import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'

// fires a GA pageview every time the route changes
export default function GoogleAnalyticsReporter({ location: { pathname, search } }) {
    useEffect(() => {
        ReactGA.pageview(`${pathname}${search}`)
    }, [pathname, search])
    return null
}